export default {
  getMyBusinessNotifications(context, params, options) {
    return this.$api
      .$post('user/my-business-notification', params, options)
      .then((response) => {
        if (Array.isArray(response) || typeof response === 'string') {
          return response.slice(0, 4)
        }
        return []
      })
  }
}
