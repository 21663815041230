export default {
  resetNotificationState(state) {
    state.notifications = []
  },
  addNotification(state, notification) {
    const requiredKeys = ['date', 'title', 'message', 'icon']

    // Validate the notification object
    const isValid = requiredKeys.every((key) => key in notification)

    if (!isValid) {
      console.error(
        'Invalid notification object, missing values:',
        notification
      )
      return
    }
    state.notifications.push(notification)
    state.lastupdate = new Date()
  }
}
